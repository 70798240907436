export default {
  slideIndex: 1,
  $modalGallery: null,

  init() {
    this.$modalGallery = $('#myModal')
    this.showSlides(this.slideIndex);


    $('.timemachine-link').on('click', e => {
      e.preventDefault();
      this.openModal();
      this.currentSlide(1)
    });

    $('#myModal .close').on('click', e => {
      e.preventDefault();
      this.closeModal()
    });

    $('.column .demo').on('click', e => {
      const $currentTarget = $(e.currentTarget)
      this.currentSlide($currentTarget.data('index') + 1)
    })

    $('#myModal .next').on('click', e => {
      this.plusSlides(1)
    });


    $('#myModal .prev').on('click', e => {
      this.plusSlides(-1)
    });

  },
  finalize() {
    // JavaScript to be fired on the home page, after the init function

  },

  // Open the Modal
  openModal() {
    this.$modalGallery.fadeIn()
  },

  // Close the Modal
   closeModal() {
     this.$modalGallery.fadeOut()
   },

  // Next/previous controls
   plusSlides(n) {
    this.showSlides(this.slideIndex += n);
  },

    // Thumbnail image controls
    currentSlide(n) {
      this.showSlides(this.slideIndex = n);
    },

    showSlides(n) {
      let i;
      let slides = document.getElementsByClassName("mySlides");
      let dots = document.getElementsByClassName("demo");
      let captionText = document.getElementById("caption");
      if (n > slides.length) {this.slideIndex = 1}
      if (n < 1) {this.slideIndex = slides.length}
      $.when($('.mySlides').fadeOut('fast')).then( () =>{
        $(slides[this.slideIndex-1]).fadeIn()
      })
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
      //slides[this.slideIndex-1].style.display = "block";
      dots[this.slideIndex-1].className += " active";
      captionText.innerHTML = dots[this.slideIndex-1].alt;
    }

};
