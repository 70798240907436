import * as bootstrap from 'bootstrap/dist/js/bootstrap';
export default {

  // cache some selectors
  $window: null,
  $body: null,
  $document: null,

  init() {

    this.$window = $(window);
    this.socialWall();

    this.$body = $('body');
    this.root = document.documentElement;
    this.$document = $(document);
    this.$mainHeader = $('header.main-header');
    this.$mainNavsContainer = $('.primary-navigation-buttons-container');

    // prevent navigate to #
    this.$document.on('click', 'a[href="#"]', function (event) {
      event.preventDefault();
    });


    /** Update header height for css transition */
    this.updateHeaderHeight()
    this.updateNavButtonsWidth()

    $(window).on('resize', () => {
      //this.handleMarginMain();
      this.updateHeaderHeight()
      this.updateNavButtonsWidth()
    });

    /**************************************************/
    // page scroll detection
    /**************************************************/

    const onPageScroll  = () => {
      if(this.$window.scrollTop() > 100)
        this.$body.addClass('scroll');
      else
        this.$body.removeClass('scroll');

      this.root.style.setProperty('--header-top-bar-height-negative', '-' + this.$mainHeader.outerHeight() + 'px');
      this.root.style.setProperty('--header-top-bar-height-positive',  this.$mainHeader.outerHeight() + 'px');

    };

    this.$window.on('scroll.pageScroll resize.pageScroll orientationchange.pageScroll', onPageScroll);
    onPageScroll();

    /**************************************************/
    // animate search on mobile
    /**************************************************/
    this.animateSearch()

    /**************************************************/
    // animate offcanvas buttons on open
    /**************************************************/
    //this.handleNavButtons()



    let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    let popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      let options = {
        html: true,
        //html element
        //content: $("#popover-content")
        //Doing below won't work. Shows title only
        //content: $("#popover-content").html()

      }

      return new bootstrap.Popover(popoverTriggerEl, options)
    })

    this.handleOffcanvas()

  },

  finalize() {

  },

  updateHeaderHeight() {
    this.root.style.setProperty('--header-height',  this.$mainHeader.outerHeight() + 'px');
    //this.$mainNavsContainer.addClass('loaded');
  },

  animateSearch() {
    const $btnSearch = $('.search-form button');

    $btnSearch.on('click', e => {
        e.preventDefault();
        $btnSearch.siblings().toggleClass('animate');

        if($('#inputSearch').hasClass('animate')) {
          $('#inputSearch').trigger('focus');
        }
        
    })

  },

  handleNavButtons() {
    const $primaryNavButtons = $('.primary-navigation-buttons-container button');

    $primaryNavButtons.on('click', e => {
      const $currTarget = $(e.currentTarget);

      $currTarget.removeClass('unactive')
      $currTarget.siblings().toggleClass('unactive')

    });

    const $offCanvasCloseBtn = $('.offcanvas-header .btn-close');

    $offCanvasCloseBtn.on('click', e => {
      if (window.innerWidth < 992) {
        $primaryNavButtons.removeClass('unactive')
      }
    });
  },

  updateNavButtonsWidth() {
    this.root.style.setProperty('--nav-container-width',  this.$mainNavsContainer.outerWidth() + 'px');

  },

  handleOffcanvas() {

    const $btn = $('.oc-controller')
    const offCanvas = []

    $btn.each((i, e) => {
      const $cur = $(e)
      console.log($cur.data('offcanvas-target'))
      offCanvas[$cur.data('offcanvas-target')] = new bootstrap.Offcanvas($cur.data('offcanvas-target'))
    })

    $btn.on('click', e => {
      e.preventDefault()

      const $cur = $(e.currentTarget)
      const bsOffcanvas = offCanvas[$cur.data('offcanvas-target')]

      if(!$cur.hasClass('active')) {
        /// cerco altri offcanvas aperti
        const $btnOpen = $('.oc-controller.active')
        if($btnOpen.length) {
          $btnOpen.removeClass('active')
          const offCanvasOpened = offCanvas[$btnOpen.data('offcanvas-target')]
          offCanvasOpened.hide()
        }
        bsOffcanvas.show()
        $cur.addClass('active').removeClass('unactive')
        $btn.not($cur).addClass('unactive')
      } else {
        bsOffcanvas.hide()
        $cur.removeClass('active')
        $btn.removeClass('unactive')
      }

    })

    const $offCanvasCloseBtn = $('.offcanvas-header .btn-close');

    $offCanvasCloseBtn.on('click', e => {
      if (window.innerWidth < 992) {
        $btn.removeClass('open').removeClass('unactive').removeClass('active')
      }
    });
  },
  
  socialWall(){
    const $src = "<script src=\"https://hubs.tellitapp.com/public/javascripts/mm_embed_host.dist.js\" type=\"text/javascript\" charset=\"utf-8\"></script>";
    $(window).on('load', function(){
      $('#socialWallContainer').html($src);
    });
  },

};

