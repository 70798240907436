export default {
  init() {
      // JavaScript to be fired on the home page

    /** Main slider handle*/
    this.handlePhotoGallerySlider(".slider-area", '.slider-filters');
    this.handlePhotoGallerySlider(".slider-area-by-tag", '.slider-filters-by-tag');
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init function

  },

  handlePhotoGallerySlider(sliderSelector, filtersSelector) {
    const $slider = $(sliderSelector).slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      arrows: true,
      rows: 0,
      responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                }
            },
          {
              breakpoint: 992,
              settings: {
                  slidesToShow: 3,
              }
          },
          {
              breakpoint: 768,
              settings: {
                  slidesToShow: 2,
              }
          },
          {
              breakpoint: 576,
              settings: {
                  slidesToShow: 1,
              }
          },
        ]
    });

    const $sliderFilters = $(filtersSelector).slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      rows: 0,
      prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style=""><i class="fas fa-arrow-left"></i></button>',
      nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style=""><i class="fas fa-arrow-right"></i></button>',
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                }
            },
        ]
    })

      $slider.slickLightbox({
        //src: 'src',
        itemSelector: '.item img',
        caption: 'caption',
        lazy: true,
        src: function(element) {
          return element.getAttribute('data-full-image')
        }
    });

    $sliderFilters.find('a').on('click', (e) => {
      e.preventDefault();
      const filter = $(e.currentTarget).data('filter');

      console.log(filter, $slider)

      $slider.slick('slickUnfilter');
      $slider.slick('slickFilter', '.' + filter);

    })
  },


};
