import {domReady} from '@roots/sage/client';
import 'bootstrap';
import Router from './js/util/Router';
import commonRoute from './js/routes/common';
import homePageRoute from './js/routes/homePage';
import singleGallerieRoute from './js/routes/singleGallerie';
import singleTerritoriRoute from "./js/routes/singleTerritori";
import 'jquery';
import 'slick-slider';
import 'slick-lightbox';

/**
 * app.main
 */
const main = async (err) => {
  if (err) {
    // handle hmr errors
    console.error(err);
  }

  // application code
};

/**
 * Initialize
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
domReady(main);
import.meta.webpackHot?.accept(main);



/** Populate Router instance with DOM routes */
const routes = new Router({
  commonRoute, // All pages
  homePageRoute,
  singleGallerieRoute,
  singleTerritoriRoute
});

// Load Events
jQuery(document).ready(() => {
  let Shuffle = window.Shuffle;


  routes.loadEvents()
});

//expose jquery for external use
window.jQueryExternal = jQuery;

window.isInternetExplorer11 = function () {
  return /MSIE|Trident/.test(navigator.userAgent); // navigator.userAgent.toLowerCase().indexOf('trident') > -1
};

if(window.isInternetExplorer11()) {

  //console.log('ECMAScript 6 compatibility shims for IE11 for Object.keys function');

  Object.keys = (function () {
    'use strict';
    var hasOwnProperty = Object.prototype.hasOwnProperty,
      hasDontEnumBug = !({toString: null}).propertyIsEnumerable('toString'),
      dontEnums = [
        'toString',
        'toLocaleString',
        'valueOf',
        'hasOwnProperty',
        'isPrototypeOf',
        'propertyIsEnumerable',
        'constructor'
      ],
      dontEnumsLength = dontEnums.length;

    return function (obj) {
      if (typeof obj !== 'object' && (typeof obj !== 'function' || obj === null)) {
        //throw new TypeError('Object.keys called on non-object');
      }

      var result = [], prop, i;

      for (prop in obj) {
        if (hasOwnProperty.call(obj, prop)) {
          result.push(prop);
        }
      }

      if (hasDontEnumBug) {
        for (i = 0; i < dontEnumsLength; i++) {
          if (hasOwnProperty.call(obj, dontEnums[i])) {
            result.push(dontEnums[i]);
          }
        }
      }
      return result;
    };
  }());
}
